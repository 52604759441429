<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:actions="actions"
			:externalFilter="externalFilter"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:actions="actions"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
			:externalFilter="externalFilter"
		></pui-datatable>
	</div>
</template>

<script>
import shipcertActions from './ShipcertActions';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'shipcertgrid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'shipcert',
			actions: shipcertActions.gridactions,
			modelColumnDefs: {
				startdate: { withoutHours: true },
				enddate: { withoutHours: true }
			}
		};
	}
};
</script>
